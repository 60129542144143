<template>
  <div class="myPost">
    <Nav></Nav>
    <div class="shopCar-con desktop-present">
      <div class="shopCar-btn">
        <div class="btn">
          <el-button type="primary" @click="onBuyPic"
            >进行 <span>({{ selectedLength }})</span>张图购买</el-button
          >
          <el-button type="primary" @click="onDelPic"
            >移除购物车 <span>({{ selectedLength }})</span>张图</el-button
          >
          <el-button type="primary" @click="onDelPic">刷新全部</el-button>
        </div>
        <div class="search">
          <el-input class="serarch-input" v-model="search" placeholder="所搜即所得" @keyup.enter.native="onSearch"></el-input>
          <div class="icon el-icon-search" @click="onSearch"></div>
        </div>
      </div>
      <div class="shop-model">
        <div class="buy-name">购买模式：</div>
        <div class="buy-con">
          <el-radio-group v-model="buyModelId" class="papershow" @change="onChangeRadio">
            <el-radio v-for="k in authModeList" :key="k.id" :label="k.id">
              {{ k.name }}
            </el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="pics-allSelect">
        <el-checkbox @change="onAllSelected" v-model="allCheck" :indeterminate="isIndeterminate">全选</el-checkbox>
      </div>
      <div class="pager-container">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="pager.currentPage"
          :page-size="pager.pageSize"
          :hide-on-single-page="false"
          layout="prev, pager, next, jumper"
          :total="pager.total"
        ></el-pagination>
      </div>
      <div class="shopCar-list">
        <div class="shop-img" v-for="(p, navIndex) in shopCarList" :key="p.id + '_nav'">
          <el-checkbox class="selected" v-model="p.selectedPic" @change="onChangeSelect"></el-checkbox>
          <img
            class="bg-img"
            :src="p.sImgURL"
            :class="{
              active: picIndex == navIndex,
            }"
          />
          <div class="pictext">
            <div class="picId">图片ID:{{ p.picId }}</div>
            <!-- <div class="author">稿件作者：{{ p.postAuthor }}</div> -->
            <div class="title">稿件标题：{{ p.postTitle }}</div>
            <div class="fen-main">图片说明：{{ p.picText }}</div>
            <div class="fen">
              扫码支付价格：
              <span v-if="selectModelObject.rate != ''">{{ (p.price * selectModelObject.rate) / 100 }} 元</span>
              <span v-else>0元</span>
            </div>
            <div class="fen">
              余额支付价格：
              <span v-if="selectModelObject.rate != ''"
                >{{ (p.price * selectModelObject.rate * selectModelObject.virtualPayDiscount * syncInfo.extra.virtualPayRate) / 100 }} 元</span
              >
              <span v-else>0元</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="shopCar-con mobile-present"></div>
    <!-- 个人信息填写 -->
    <el-dialog :title="'用于' + selectModelObject.name" :visible.sync="buyInfoShow" width="50%">
      <div class="buy-info" v-if="selectModelObject.extra">
        <div v-for="(k, fieldIndex) in selectModelObject.extra.requiredFields" :key="'field_' + fieldIndex + k">
          <div class="buy-name">
            <span>{{ k.title }}: <i v-if="k.compulsory">(*)</i></span>
            <div class="item-con">
              <div class="item" v-for="(h, subIndex) in k.subs" :key="'subs_' + subIndex + h" v-bind:class="{ red: h.error }">
                <el-input v-if="h.type == 'STRING'" v-model="h.value" :placeholder="'请输入' + k.title"></el-input>
                <el-input v-if="h.type == 'MOBILE'" v-model="h.value" :placeholder="'请输入' + k.title"></el-input>
                <el-input v-if="h.type == 'AREA'" type="textarea" :rows="4" :placeholder="'请输入' + k.title" v-model="h.value"> </el-input>
                <div class="date" v-if="h.type == 'DATE'">
                  <div class="date-item">
                    <span class="date-title" v-if="h.title != ''">{{ h.title }}</span>
                    <el-date-picker :editable="false" value-format="yyyy-MM-dd" :clearable="!k.compulsory" v-model="h.value" type="date" @change="onUseChange"></el-date-picker>
                  </div>
                </div>
                <div class="pos" v-if="h.type == 'POS'">
                  <el-select v-model="h.value" placeholder="请选择">
                    <el-option v-for="item in paperList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <span>({{ k.tips }})</span>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="buyInfoShow = false">取 消</el-button>
        <el-button type="primary" @click="onSureBuy">继续购买</el-button>
      </span>
    </el-dialog>
    <!-- 选择支付方式 -->
    <el-dialog title="请选择支付模式" :visible.sync="payModelShow" width="30%" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
      <div class="pay-model">
        <div class="name">订单Id:{{ toOrderObj.id }}</div>
        <div class="con">
          <el-radio-group v-model="modelFlag">
            <div class="item" v-if="virtualShow">
              <el-radio label="1">
                <i>余额付:</i>
                <span>当前余额：{{ syncInfo.credit }} 元</span>
                <span>价格：{{ toOrderObj.virtualPayPrice }} 元</span>
              </el-radio>
            </div>
            <div class="item" v-if="realPayShow">
              <el-radio label="2">
                <i>扫码付:</i>
                <span>价格：{{ toOrderObj.price }} 元</span>
              </el-radio>
            </div>
          </el-radio-group>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onDelOrder">暂不支付</el-button>
        <el-button type="primary" @click="onPayOrder">继续支付</el-button>
      </span>
    </el-dialog>

    <!-- 扫码订单 -->
    <el-dialog title="扫码支付(微信或支付宝)" :visible.sync="scanShow" width="30%" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
      <div class="scan">
        <div class="scan-con">
          <img :src="scanImgURL" alt="" class="scan-img" />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onCloseScan">关闭窗口</el-button>
      </span>
    </el-dialog>

    <form :action="buyPicUrl" ref="buyPicForm" method="POST" target="_blank">
      <input type="hidden" name="orderPicIds" :value="picIdsString" />
    </form>

    <Bottom></Bottom>
  </div>
</template>

<script>
var timers = []

import RD from '@/api/RD'
import Nav from '@/components/nav.vue'
import Bottom from '@/components/bottom.vue'

export default {
  components: { Nav, Bottom },
  data: function () {
    return {
      shopCarList: [],
      search: '', // 购物车搜索
      buyPicUrl: '',
      picIdsString: '',
      picIds: [], // 被勾选的图片id
      selectedLength: 0, // 勾选的图片总数
      hasLength: false, // 是否被选中
      isIndeterminate: false, // 半选的状态
      picIndex: -1,
      allCheck: false, //默认全不选的状态
      buyInfoShow: false, // 是否显示 填写购买者信息
      scanShow: false, // 扫码支付是否显示
      scanImgURL: '', // 扫码的图片URL
      orderId: '',
      authModeList: [], // 购买模式
      fieldList: [], // 所有的购买模式
      buyModelId: '', // 选中的购买模式的Id
      selectModelObject: { rate: 0 }, // 被选中的模式
      modelObj: {},
      payModelShow: false,
      syncInfo: { extra: { virtualPayRate: 1 } },
      virtualShow: false,
      modelFlag: '',
      toOrderObj: {},
      redValue: '  ',
      realPayShow: false,
      pager: {
        currentPage: 1,
        total: 1,
        totalPage: 1,
      },
      paperList: [
        {
          value: '封底',
          label: '封底',
        },
        {
          value: '封面',
          label: '封面',
        },
        {
          value: '正文',
          label: '正文',
        },
      ],
    }
  },
  created() {
    var thiz = this
    RD.pure()
      .user()
      .sync()
      .then((data) => {
        this.syncInfo = data
        thiz.virtualShow = data.noStoreRights.indexOf('CREDIT_PAY') != -1
        if (thiz.virtualShow) {
          thiz.modelFlag = '1'
        }
        thiz.realPayShow = data.noStoreRights.indexOf('REAL_PAY') != -1
        if (thiz.realPayShow) {
          thiz.modelFlag = '2'
        }

        if (thiz.virtualShow && thiz.realPayShow) {
          thiz.modelFlag = '1'
        }
      })
      .catch(() => {
        console.log('catch')
        this.$alert('您当前处于未登录状态, 请先登录?', '提示', {
          confirmButtonText: '确定',
          type: 'warning',
        })
          .then(() => {
            this.$router.push('/picture.html')
          })
          .catch(() => {})
      })
    this.reload()
    this.time()
  },
  methods: {
    p(s) {
      return s < 10 ? '0' + s : s
    },
    reload() {
      var thiz = this
      RD.myPost()
        .listPicCart(thiz.pager.currentPage, 20)
        .then((data) => {
          data.list.forEach((k) => {
            k.sImgURL = RD.pic().id(k.picId).sImage_URL()
            k.selectedPic = false
          })
          thiz.shopCarList = data.list
          thiz.pager = data.pager
        })
        .catch((errMsg) => {
          console.log('errmsg', errMsg)
          thiz.$message(errMsg.msg)
        })
      RD.authMode()
        .authModes()
        .then((data) => {
          thiz.authModeList = data
        })
      thiz.buyModelId = ''
      thiz.allCheck = false
      thiz.fieldGet()
    },
    // 购物车搜索
    onSearch() {
      const thiz = this
      console.log('search', thiz.search)
    },
    handleCurrentChange(val) {
      var thiz = this
      thiz.pager.currentPage = val
      thiz.reload()
    },
    // 勾选的变化
    onChangeSelect() {
      var thiz = this
      thiz.picIds = []
      thiz.shopCarList.forEach((k) => {
        if (k.selectedPic == true) {
          thiz.picIds.push(k.id)
        }
      })
      thiz.selectedLength = thiz.picIds.length
      if (thiz.picIds.length >= 1) {
        thiz.hasLength = true
      }
      if (thiz.picIds.length == thiz.shopCarList.length) {
        thiz.allCheck = true
        thiz.isIndeterminate = false
      } else if (thiz.picIds.length == 0) {
        thiz.allCheck = false
        thiz.isIndeterminate = false
      } else if (thiz.picIds.length >= 1) {
        thiz.isIndeterminate = true
      }
    },
    // 全选
    onAllSelected() {
      var thiz = this
      if (thiz.allCheck) {
        var allSelected = []
        thiz.shopCarList.forEach((k) => {
          k.selectedPic = true
          allSelected.push(k.id)
        })
        thiz.selectedLength = allSelected.length
        if (allSelected.length > 1) {
          thiz.hasLength = true
        }
        thiz.picIds = allSelected
        if (thiz.picIds.length == thiz.shopCarList.length) {
          thiz.allCheck = true
          thiz.isIndeterminate = false
        }
      } else {
        thiz.picIds = []
        thiz.isIndeterminate = false
        thiz.selectedLength = 0
        thiz.allCheck = false
        thiz.shopCarList.forEach((k) => {
          k.selectedPic = false
        })
      }
    },
    // 购买图片
    onBuyPic() {
      console.log('sel', this.picIds)
      var thiz = this
      thiz.onChangeRadio()

      if (thiz.picIds == 0) {
        this.$alert('请先勾选要购买的图片', '提示', {
          confirmButtonText: '确定',
          type: 'warning',
          callback: (action) => {},
        })
      } else if (thiz.buyModelId == '') {
        this.$alert('请先勾选要购买的模式', '提示', {
          confirmButtonText: '确定',
          type: 'warning',
          callback: (action) => {},
        })
      } else if (thiz.picIds != 0 && thiz.buyModelId != '') {
        thiz.buyInfoShow = true
      }
    },
    fieldGet() {
      var thiz = this
      RD.authMode()
        .fields()
        .then((data) => {
          thiz.fieldList = data
        })
    },
    onSureBuy() {
      var thiz = this

      for (var item of thiz.authModeList) {
        if (item.id == thiz.buyModelId) {
          for (var fields of item.extra.requiredFields) {
            for (var target of fields.subs) {
              target.error = false
              var mobile = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/
              if (target.type == 'MOBILE' && target.value != '' && !mobile.test(target.value)) {
                this.$alert('手机号不合法, 请重新填写', '提示', {
                  confirmButtonText: '确定',
                  callback: (action) => {},
                })
                target.error = true
                return
              }
              if (fields.compulsory == true) {
                if (target.value == '') {
                  var msg = fields.title + '不能为空'
                  this.$alert(msg, '提示', {
                    confirmButtonText: '确定',
                    callback: (action) => {},
                  })
                  target.error = true
                  return
                }
              }
            }
          }
        }
      }

      var obj = {}
      for (var mode of thiz.authModeList) {
        if (mode.id == thiz.buyModelId) {
          console.log('info', mode.extra.requiredFields)
          for (var field of mode.extra.requiredFields) {
            for (var target of field.subs) {
              obj[target.name] = target.value
            }
          }
        }
      }
      console.log('final object', obj)

      RD.pure()
        .purchaseOrder()
        .toOrder(thiz.picIds, thiz.buyModelId, obj)
        .then((data) => {
          thiz.buyInfoShow = false
          thiz.picIds = []
          thiz.selectedLength = 0
          thiz.orderId = data.id
          thiz.toOrderObj = data
          if (thiz.selectModelObject.needApprove) {
            this.$confirm('订单必须被审核通过才能支付，欢迎致电：025-58680317或18013890658', '提示', {
              confirmButtonText: '进入待审核订单',
              cancelButtonText: '关闭窗口',
              type: 'warning',
            })
              .then(() => {
                let routerUrl = this.$router.resolve({
                  path: '/myOrder.html',
                  query: { flag: 2 },
                })
                window.open(routerUrl.href, '_blank')
              })
              .catch(() => {})
            thiz.buyInfoShow = false
            thiz.reload()
          } else {
            thiz.payModelShow = true
            console.log('osp', thiz.modelFlag)
          }
        })
        .catch((errMsg) => {
          console.log('errmsg', errMsg)
          thiz.$alert(errMsg.msg)
          thiz.buyInfoShow = false
        })
    },
    onPayOrder() {
      var thiz = this
      console.log('model', thiz.modelFlag)
      if (thiz.modelFlag == 2) {
        thiz.sureOrder()
      } else if (thiz.modelFlag == 1) {
        RD.purchaseOrder()
          .id(thiz.orderId)
          .payWithCredit()
          .then(() => {
            this.$confirm('余额支付成功', '提示', {
              confirmButtonText: '下载图片',
              cancelButtonText: '暂不下载',
              type: 'success',
            })
              .then(() => {
                thiz.sameDownPic()
              })
              .catch(() => {
                thiz.payModelShow = false
                thiz.reload()
              })
          })
      }
    },
    // 复用的下载图片
    sameDownPic() {
      var thiz = this
      RD.purchaseOrder()
        .id(thiz.orderId)
        .listPicsOfOrder()
        .then((data) => {
          var orderIds = []
          data.forEach((k) => {
            orderIds.push(k.orderPicId)
          })
          thiz.picIdsString = JSON.stringify(orderIds)
          thiz.buyPicUrl = RD.purchaseOrder().downloadBatchByOrderPicId_URL(orderIds)
          thiz.$nextTick(() => {
            thiz.$refs.buyPicForm.submit()
          })
          thiz.payModelShow = false
          thiz.reload()
        })
    },
    // 支付订单
    sureOrder() {
      var thiz = this
      RD.purchaseOrder()
        .id(thiz.orderId)
        .payWithMoney()
        .then((data) => {
          thiz.scanImgURL = data
          thiz.scanShow = true
          timers = setInterval(() => {
            thiz.checkOrder()
          }, 2000)
        })
    },
    // 扫码之后的关闭
    onCloseScan() {
      var thiz = this
      thiz.scanShow = false
      thiz.payModelShow = false
      thiz.reload()
      clearInterval(timers)
    },
    // 进入扫码页面的setTimer
    checkOrder() {
      var thiz = this
      RD.pure()
        .purchaseOrder()
        .checkOrder(thiz.orderId)
        .then((data) => {
          clearInterval(timers)
          this.$confirm('已经成功支付，是否继续下载图片', '提示', {
            confirmButtonText: '下载图片',
            cancelButtonText: '稍后再说',
            type: 'warning',
          })
            .then(() => {
              thiz.sameDownPic()
              thiz.scanShow = false
            })
            .catch(() => {
              thiz.scanShow = false
              thiz.payModelShow = false
              thiz.reload()
            })
        })
        .catch((error) => {})
    },
    // 删除购物车里面的图片
    onDelPic() {
      var thiz = this
      RD.purchaseOrder()
        .deleteCart(thiz.picIds)
        .then(() => {
          thiz.$message('移除购物车成功')
          thiz.reload()
        })
    },
    // 开始时间
    onTimeChange() {
      var thiz = this
      var d = new Date(thiz.modelObj.timeFrom)
      thiz.modelObj.timeFrom = d.getFullYear() + '-' + this.p(d.getMonth() + 1) + '-' + this.p(d.getDate())
    },
    // 结束时间
    onEndChange() {
      var thiz = this
      var f = new Date(thiz.modelObj.timeTo)
      thiz.modelObj.timeTo = f.getFullYear() + '-' + this.p(f.getMonth() + 1) + '-' + this.p(f.getDate())
    },
    // 使用日期（报纸）
    onUseChange() {
      var thiz = this
      var d = new Date(thiz.modelObj.publishDate)
      thiz.modelObj.publishDate = d.getFullYear() + '-' + this.p(d.getMonth() + 1) + '-' + this.p(d.getDate())
    },
    time() {
      var thiz = this
      var nowTime = new Date()
      thiz.modelObj.timeTo = nowTime.getFullYear() + '-' + this.p(nowTime.getMonth() + 1) + '-' + this.p(nowTime.getDate())
      var oneweekdate = new Date(nowTime - 7 * 24 * 3600 * 1000)
      thiz.modelObj.timeFrom = oneweekdate.getFullYear() + '-' + thiz.p(oneweekdate.getMonth() + 1) + '-' + thiz.p(oneweekdate.getDate())
      thiz.modelObj.publishDate = oneweekdate.getFullYear() + '-' + thiz.p(oneweekdate.getMonth() + 1) + '-' + thiz.p(oneweekdate.getDate())
    },
    // 模式的改变
    onChangeRadio() {
      var thiz = this
      thiz.authModeList.forEach((k) => {
        if (k.id == thiz.buyModelId) {
          // thiz.fieldList
          for (var i = 0; i < thiz.fieldList.length; i++) {
            k.extra.requiredFields.forEach((h) => {
              if (h.field == thiz.fieldList[i].key) {
                h.title = thiz.fieldList[i].title
                h.tips = thiz.fieldList[i].tips
                h.subs = []
                thiz.fieldList[i].targets.forEach((target) => {
                  var to = {}
                  to.title = target.title
                  to.name = target.name
                  to.error = false
                  to.value = ''
                  if (h.compulsory && target.type == 'DATE') {
                    var date = new Date()
                    to.value =
                      date.getFullYear() +
                      '-' +
                      ((date.getMonth() + 1 + '').length == 1 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) +
                      '-' +
                      ((date.getDate() + '').length == 1 ? '0' + date.getDate() + '' : date.getDate() + '')
                  }
                  to.type = target.type
                  h.subs.push(to)
                })
              }
            })
            k.extra.requiredFields = JSON.parse(JSON.stringify(k.extra.requiredFields))
          }

          thiz.selectModelObject = k
        }
      })
    },
    onClickModel(type) {
      var thiz = this
      thiz.modelFlag = type
    },
    // 支付方式的取消，订单已经生成
    onDelOrder() {
      var thiz = this
      thiz.payModelShow = false
      thiz.reload()
    },
  },
}
</script>

<style scoped lang="less">
.myPost {
  .shopCar-con.desktop-present {
    width: 120rem;
    margin: 0 auto;
    min-height: 58.5rem;
    background-color: #f1f1f1;
    .pics-allSelect {
      height: 3rem;
      line-height: 3rem;
      text-align: left;
      margin-top: 1rem;
      margin-left: 1.5rem;
    }
    .pager-container {
      height: 5rem;
      text-align: right;
    }
    .shopCar-list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-top: 1rem;
      .shop-img {
        width: 27rem;
        height: 35.7rem;
        margin-right: 1.5rem;
        margin-left: 1.5rem;
        margin-bottom: 2rem;
        background: no-repeat 50% / cover;
        position: relative;
        .selected {
          position: absolute;
          left: 0rem;
          height: 2rem;
        }
        .bg-img {
          width: 100%;
          height: 20rem;
          margin-top: 2rem;
          background: no-repeat 50%;
          background-size: contain;
        }
        .pictext {
          text-align: left;
          font-size: 1.4rem;
          .title {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
          }
          .fen-main {
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
    .shopCar-btn {
      text-align: left;
      padding-top: 1rem;
      margin-left: 1rem;
      display: flex;
      justify-content: space-between;
      .el-button {
        span {
          color: red;
          font-size: 1.6rem;
        }
      }
      .search {
        position: relative;
        width: 40rem;
        display: flex;
        flex-shrink: 0;
        position: relative;
        .el-input {
          width: 40rem;
          margin: 0rem 0.3rem;
        }
        .icon {
          width: 5rem;
          font-size: 2rem;
          padding-top: 0.5rem;
          border-radius: 0.5rem;
          height: 3rem;
          cursor: pointer;
          position: absolute;
          top: 0.4rem;
          right: -0.2rem;
        }
      }
    }
    .shop-model {
      height: 8rem;
      text-align: left;
      margin-left: 10px;
      .buy-name {
        height: 3rem;
        line-height: 3rem;
        font-size: 1.6rem;
      }
      .buy-con {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-start;
        align-content: flex-start;
        .el-radio {
          height: 2.5rem;
          text-align: left;
          margin-top: 20px;
        }
      }
    }
  }
  .shopCar-con.mobile-present {
    width: 750px;
    margin: 0 auto;
    min-height: 585px;
  }
}
.title {
  cursor: pointer;
  a {
    text-decoration: none;
    color: #606266;
  }
  a:visited {
    color: #606266;
  }
}
.edit {
  color: red;
  cursor: pointer;
}
.buy-info {
  text-align: left;
  font-size: 1.5rem;
  max-height: 50vh;
  overflow-y: auto;
  clear: both;
  .buy-name {
    height: auto;
    display: flex;
    flex-wrap: nowrap;
    min-height: 5rem;
    line-height: 4rem;
    > span {
      display: inline-block;
      min-width: 14rem;
      margin-top: 1rem;
      text-align: right;
      margin-right: 1rem;
      i {
        font-style: normal;
        color: red;
      }
    }
    .item-con {
      text-align: left;
      .item {
        margin: 1rem 0rem;
        text-align: left;
        .date {
          .date-item {
            display: flex;
            .date-title {
              display: inline-block;
              width: 2rem;
            }
          }
        }
        .el-textarea {
          width: 30rem;
        }
      }
      .red {
        border: 1px solid red;
      }
    }
  }
}
.pay-model {
  height: 10vh;
  .name {
    line-height: 4rem;
    height: 3rem;
    text-align: left;
    font-size: 1.6rem;
  }
  .con {
    height: 100%;
    font-size: 1.6rem;

    .item {
      flex: 1;
      height: 15%;
      margin-top: 1rem;
      text-align: left;
      span {
        margin-right: 1rem;
      }
      i {
        font-style: normal;
        color: red;
        margin-right: 1rem;
      }
    }
    .red {
      border: 1px solid red;
    }
  }
}
.scan {
  .scan-con {
    width: 20rem;
    height: 20rem;
    margin: 0 auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
